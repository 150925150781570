import axios from "axios";
import UserPermissionDTO from "@/models/UserPermissionDTO";

export const userPermissions = {
    list: async (): Promise<UserPermissionDTO[]> => {
        let resp = await axios({
            url: `/user-permissions`,
            method: 'GET',
        });

        return resp.data as UserPermissionDTO[]
    },
}