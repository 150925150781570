import {Repository} from "@/service/repository/Repository";
import TraderDeviceDTO from "@/models/TraderDeviceDTO";
import {api} from "@/service/APIClient";
import PaginationDTO from "@/models/PaginationDTO";
import {Dictionary} from "@/types";
import TraderRequisitesDTO from "@/models/TraderRequisitesDTO";
import RequisitesRequestDTO from "@/models/request/RequisitesRequestDTO";

export const traderDeviceRepository: Repository<TraderDeviceDTO> = new Repository<TraderDeviceDTO>(
    async (ids: string[]) => {
        let store: Dictionary<TraderDeviceDTO> = {}
        for (const item of (await api.traderDevices({limit: ids.length, offset: 0} as PaginationDTO, {ids: ids as string[]})).items) {
            store[item.id] = item
        }
        return store
    }
);

export const requisitesRepository: Repository<TraderRequisitesDTO> = new Repository<TraderRequisitesDTO>(
    async (ids: string[]) => {
        let store: Dictionary<TraderRequisitesDTO> = {}
        for (const item of (await api.requisites({limit: ids.length, offset: 0} as PaginationDTO, {}, {ids: ids as unknown as number[]} as RequisitesRequestDTO)).items) {
            store[item.id.toString()] = item
        }
        return store
    }
);