import axios from "axios";
import TraderGroupRewardSettingsDTO from "@/models/TraderGroupRewardSettingsDTO";
import TraderGroupSummaryItemDTO from "@/models/TraderGroupSummaryItemDTO";

export const traderRewardSettings = {
    summary: async (req: any = {}): Promise<TraderGroupSummaryItemDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/trader-reward-settings/summary`,
            method: 'POST',
            data: {
                ...req
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
        });

        return resp.data as TraderGroupSummaryItemDTO[]
    },
    get: async (id: number): Promise<TraderGroupRewardSettingsDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/trader-reward-settings/${id}`,
            method: 'GET',
        });

        return resp.data as TraderGroupRewardSettingsDTO[]
    },
    exportByTeamLead: async (id: number): Promise<any> => {
        let resp = await axios({
            url: `/traffic-groups/trader-reward-settings/by-teamlead/${id}/export`,
            responseType: 'blob',
            method: 'GET',
        });

        return resp.data as any
    },
    importByTeamLead: async (id: number, file: any): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file)

        let resp = await axios({
            url: `/traffic-groups/trader-reward-settings/by-teamlead/${id}/import`,
            data: formData,
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return resp.data as any;
    },
    update: async (id: number, data: any): Promise<TraderGroupRewardSettingsDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/trader-reward-settings/${id}`,
            data,
            method: 'PUT',
        });

        return resp.data as TraderGroupRewardSettingsDTO[]
    }
}