import axios from "axios";
import PaginationDTO from "@/models/PaginationDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import qs from "qs";
import TrafficGroupAdditionalRewardSettingsDTO from "@/models/TrafficGroupAdditionalRewardSettingsDTO";
import AdditionalRewardsSettingsUpdateRequestDTO from "@/models/request/AdditionalRewardsSettingsUpdateRequestDTO";

export const additionalRewardSettings = {
    list: async (pagination: PaginationDTO, req: any = {}): Promise<PaginatedResponseDTO<TrafficGroupAdditionalRewardSettingsDTO>> => {
        let resp = await axios({
            url: `/traffic-groups/additional-reward-settings`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TrafficGroupAdditionalRewardSettingsDTO>;
    },
    create: async (requestDTO: AdditionalRewardsSettingsUpdateRequestDTO): Promise<TrafficGroupAdditionalRewardSettingsDTO> => {
        let resp = await axios({
            url: `/traffic-groups/additional-reward-settings`,
            method: 'POST',
            data: {
                ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TrafficGroupAdditionalRewardSettingsDTO;
    },
    update: async (id: number, requestDTO: AdditionalRewardsSettingsUpdateRequestDTO): Promise<TrafficGroupAdditionalRewardSettingsDTO> => {
        let resp = await axios({
            url: `/traffic-groups/additional-reward-settings/${id}`,
            method: 'PUT',
            data: {
                ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TrafficGroupAdditionalRewardSettingsDTO;
    },
    delete: async (id: number): Promise<TrafficGroupAdditionalRewardSettingsDTO> => {
        let resp = await axios({
            url: `/traffic-groups/additional-reward-settings/${id}`,
            method: 'DELETE',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TrafficGroupAdditionalRewardSettingsDTO;
    }
}