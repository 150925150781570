import {debounce} from "@/service/CommonHelper";
import {Dictionary} from "@/types";

export class Repository<T> {
    private ids: string[] = []
    private store: Dictionary<T> = {}
    private resolves: any[] = []
    private loadFn: (ids: string[]) => Promise<Dictionary<T>>

    constructor(loadFn: (ids: string[]) => Promise<Dictionary<T>>) {
        this.loadFn = loadFn;

    }

    private fetchDebounceFn = debounce(async () => {
        this.store = await this.loadFn(this.ids)
        for (const resolve of this.resolves) {
            resolve()
        }
        this.resolves = []
        this.ids = []
    }, 100);

    public getById = (id: string): Promise<T> => {
        return new Promise(async resolve => {
            const item = this.store[id];
            if (item) {
                resolve(item)
                return;
            }

            if (!this.ids.includes(id)) {
                this.ids.push(id)
            }

            this.resolves.push(() => {
                resolve(this.store[id])
            })
            await this.fetchDebounceFn()

        })
    }
}