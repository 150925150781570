import axios from "axios";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import PaginationDTO from "@/models/PaginationDTO";
import DepositDTO from "@/models/DepositDTO";

export const deposits = {
    list: async (pagination: PaginationDTO, requestDTO: any = {}): Promise<PaginatedResponseDTO<DepositDTO>> => {
        let resp = await axios({
            url: `/deposits`,
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as PaginatedResponseDTO<DepositDTO>
    },
    get: async (id: string): Promise<DepositDTO> => {
        let resp = await axios({
            url: `/deposits/${id}`,
            method: 'GET',
        });

        return resp.data as DepositDTO;
    },
    return: async (id: string): Promise<void> => {
        let resp = await axios({
            url: `/deposits/${id}/return`,
            method: 'POST',
        });
    },
    process: async (id: string): Promise<void> => {
        let resp = await axios({
            url: `/deposits/${id}/process`,
            method: 'POST',
        });
    },
    renewAMLDepositCheck: async (id: string): Promise<void> => {
        let resp = await axios({
            url: `/deposits/${id}/renew-aml-check`,
            method: 'POST',
        });
    }
}