import axios from "axios";
import PaginationDTO from "@/models/PaginationDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import TrafficGroupTraderManualPriorityDTO from "@/models/TrafficGroupTraderManualPriorityDTO";
import ManualPriorityCreateRequestDTO from "@/models/request/ManualPriorityCreateRequestDTO";
import ManualPriorityUpdateRequestDTO from "@/models/request/ManualPriorityUpdateRequestDTO";
import qs from "qs";

export const traderManualPriorities = {
    list: async (pagination: PaginationDTO, req: any = {}): Promise<PaginatedResponseDTO<TrafficGroupTraderManualPriorityDTO>> => {
        let resp = await axios({
            url: `/traffic-groups/trader-manual-priorities`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TrafficGroupTraderManualPriorityDTO>;
    },
    create: async (requestDTO: ManualPriorityCreateRequestDTO): Promise<TrafficGroupTraderManualPriorityDTO> => {
        let resp = await axios({
            url: `/traffic-groups/trader-manual-priorities`,
            method: 'POST',
            data: {
                ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TrafficGroupTraderManualPriorityDTO;
    },
    update: async (id: number, requestDTO: ManualPriorityUpdateRequestDTO): Promise<TrafficGroupTraderManualPriorityDTO> => {
        let resp = await axios({
            url: `/traffic-groups/trader-manual-priorities/${id}`,
            method: 'PUT',
            data: {
                ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TrafficGroupTraderManualPriorityDTO;
    },
    delete: async (id: number): Promise<TrafficGroupTraderManualPriorityDTO> => {
        let resp = await axios({
            url: `/traffic-groups/trader-manual-priorities/${id}`,
            method: 'DELETE',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TrafficGroupTraderManualPriorityDTO;
    }
}