import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from "@/views/Dashboard.vue";
import Requisites from "@/views/Requisites.vue";
import Deals from "@/views/Deals.vue";
import Settings from "@/views/Settings.vue";
import AccountOperations from "@/views/AccountOperations.vue";
import {UserRole} from "@/models/enum/UserRole";
import Invoices from "@/views/Invoices.vue";
import AdminDashboard from "@/views/AdminDashboard.vue";
import Wallets from "@/views/Wallets.vue";
import Users from "@/views/Users.vue";
import Routing from "@/views/Routing.vue";
import InviteLogin from "@/views/InviteLogin.vue";
import OperatorTraders from "@/views/OperatorTraders.vue";
import RequisitesGroups from "@/views/RequisitesGroups.vue";
import TraderDevices from "@/views/TraderDevices.vue";
import Statistic from "@/views/Statistic.vue";
import AntiFraudAlerts from "@/views/AntiFraudAlerts.vue";
import Transfers from "@/views/Transfers.vue";
import SystemSettings from "@/views/SystemSettings.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.MERCHANT, UserRole.MERCHANT_OPERATOR, UserRole.TRADER_TEAM_LEAD, UserRole.REFERRAL]},
    },
    {
        path: '/admin/accounts',
        name: 'adminDashboard',
        component: AdminDashboard,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/admin/wallets',
        name: 'wallets',
        component: Wallets,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/admin/users',
        name: 'users',
        children: [
            {
                name: 'users',
                path: '/admin/users/:type/:id?',
                component: Users
            },
        ],
        component: Users,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/traders',
        name: 'traders',
        component: OperatorTraders,
        meta: {requiresAuth: true, roles: [UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD]},
    },
    {
        path: '/admin/routing',
        name: 'routing',
        component: Routing,
        children: [
            {
                name: 'routing',
                path: '/admin/routing/:tab',
                component: Routing,
                meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR]},
            },
        ],
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR]},
    },
    {
        path: '/devices',
        name: 'devices',
        component: TraderDevices,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD, UserRole.TRADER]},
    },
    {
        path: '/statistic',
        name: 'statistic',
        component: Statistic,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD, UserRole.TRADER, UserRole.MERCHANT, UserRole.MERCHANT_OPERATOR, UserRole.REFERRAL]},
    },
    {
        path: '/admin/transfers',
        name: 'transfers',
        component: Transfers,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR]},
        children: [
            {
                name: 'transfers',
                path: '/admin/transfers/:tab',
                component: Transfers,
                meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR]},
            },
        ],
    },
    {
        path: '/deals',
        name: 'deals',
        component: Deals,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD, UserRole.ADMIN, UserRole.REFERRAL]},
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Invoices,
        meta: {requiresAuth: true, roles: [UserRole.MERCHANT, UserRole.MERCHANT_OPERATOR, UserRole.ADMIN]},
    },
    {
        path: '/account-operations',
        name: 'accountOperations',
        component: AccountOperations,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.MERCHANT, UserRole.MERCHANT_OPERATOR, UserRole.ADMIN, UserRole.TRADER_TEAM_LEAD, UserRole.REFERRAL]},
    },
    {
        path: '/requisites',
        name: 'requisites',
        component: Requisites,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.ADMIN, UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD]},
    },
    {
        path: '/anti-fraud-alerts',
        name: 'antiFraudAlerts',
        component: AntiFraudAlerts,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR]},
    },
    {
        path: '/requisites-groups',
        name: 'requisitesGroups',
        component: RequisitesGroups,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD]},
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requiresAuth: true,
            roles: [UserRole.TRADER, UserRole.OPERATOR, UserRole.TRADER_TEAM_LEAD, UserRole.MERCHANT, UserRole.MERCHANT_OPERATOR, UserRole.ADMIN, UserRole.REFERRAL]
        },
    },
    {
        path: '/system-settings',
        name: 'systemSettings',
        component: SystemSettings,
        meta: {
            requiresAuth: true,
            roles: [UserRole.ADMIN]
        },
    },
    {path: '/login', name: 'login', component: Login},
    {path: '/invite/:token', name: 'inviteLogin', component: InviteLogin},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

